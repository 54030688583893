import { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Icon,
    IconButton,
    TextField,
    Modal,
    Fade,
    Button,
    InputAdornment,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';

import AlertContext from 'core/contexts/Alert';
import AuthContext from 'core/contexts/Auth';
import { api } from 'core/lib/api';
import PreviewImage from 'core/components/PreviewImage';
import { getUserData } from 'core/lib/getUserData';

export interface ProductsProps {
    instance?: Record<string, any>;
    editable?: boolean;
}

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        borderTop: '1px solid rgb(221, 221, 221)',
        margin: '20px -30px -20px',
        width: 'calc(100% + 40px)',
        padding: '40px 30px',
    },
    title: {
        fontWeight: 'bold',
        fontSize: theme.typography.pxToRem(17),
        color: '#444444',
        marginBottom: theme.spacing(2.5),
    },
    add: {
        marginLeft: theme.spacing(1),
    },
    tableContainer: {
        scrollbarColor: '#456bef',
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': {
            width: 6,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#456bef',
            borderRadius: 20,
        },
        '& table': {
            '& thead': {
                '& tr': {
                    '& th': {
                        fontWeight: 'bold',
                        fontSize: theme.typography.pxToRem(15),
                        color: '#444444',
                        border: 'none',
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1),
                        '&:first-of-type': {
                            paddingLeft: 0,
                        },
                        '&:last-of-type': {
                            paddingRight: 0,
                        },
                    },
                },
            },
            '& tbody': {
                '& tr': {
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    '& th': {
                        fontWeight: 'bold',
                        fontSize: theme.typography.pxToRem(13),
                        color: '#456AEF',
                        paddingLeft: 0,
                        height: 50,
                        display: 'flex',
                        alignItems: 'center',
                        border: 0,
                        '& img': {
                            height: 30,
                            width: 30,
                            marginRight: 10,
                            objectFit: 'cover',
                        },
                    },
                    '& td': {
                        border: 0,
                        paddingTop: theme.spacing(1),
                        paddingBottom: theme.spacing(1),
                        fontSize: theme.typography.pxToRem(14),
                        color: '#444444',
                        '&:last-of-type': {
                            paddingRight: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                        },
                        '& input': {
                            textAlign: 'center',
                            padding: '5px 0',
                            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                                {
                                    WebkitAppearance: 'none',
                                    margin: 0,
                                },
                            '&[type=number]': {
                                MozAppearance: 'textfield',
                            },
                        },
                    },
                },
            },
        },
    },
    promotional: {
        textDecoration: 'line-through',
        color: 'rgba(0, 0, 0, 0.5)',
    },
    promotionalIndicator: {
        width: 10,
        height: 10,
        backgroundColor: '#ffd700',
        borderRadius: '50%',
        marginLeft: 10,
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 150,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#fff',
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'column',
        outline: 'unset',
        alignItems: 'center',
        position: 'relative',
        width: 930,
        height: 510,
    },
    modalHeader: {
        padding: theme.spacing(2, 4),
        display: 'flex',
        alignItems: 'center',
        borderTopLeftRadius: 'inherit',
        borderTopRightRadius: 'inherit',
        backgroundColor: '#F9F9F9',
        width: '100%',
        '&>p': {
            fontSize: theme.typography.pxToRem(17.5),
            color: '#333333',
            marginRight: theme.spacing(5),
            width: 235,
        },
    },
    modalBody: {
        padding: theme.spacing(4),
        width: '100%',
        height: '100%',
    },
    modalFooter: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: theme.spacing(4),
    },
    modalButton: {
        backgroundColor: '#456AEF',
        color: '#fff',
        textTransform: 'capitalize',
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 'bold',
        height: 40,
        padding: '6px 16px',
        width: 100,
        '&:hover': {
            transition: 'background-color 500ms linear',
            backgroundColor: 'rgba(69, 106, 239, 0.5)',
        },
        '&.cancel': {
            border: '1px solid #456AEF',
            background: 'white',
            color: '#456AEF',
        },
        '& + button': {
            marginLeft: '20px',
        },
    },
}));
const marketplaceId = getUserData().marketplace.id;
function currency(price: any) {
    price = parseFloat(parseFloat(price).toFixed(2)).toLocaleString('pt-BR', {
        currency: 'BRL',
    });

    if (price.indexOf(',') === -1) price += ',00';
    else if (price[price.length - 2] === ',') price += '0';

    return `R$ ${price}`;
}

function TableCustom(props: {
    products: any[];
    type: 'add' | 'remove';
    style?: { [key: string]: any };
}) {
    const classes = useStyles();
    const { products, type, style } = props;

    const [refresh, setRefresh] = useState(false);

    const { error } = useContext(AlertContext);

    let interval: any = null;

    function handleProduct(product: any, index: number) {
        if (type === 'add') addProduct(product);
        else if (type === 'remove') removeProduct(product, index);
    }

    function addProduct(product: any) {
        if (product.add) product.add = undefined;
        else product.add = true;

        setRefresh(!refresh);
    }

    async function removeProduct(product: any, index: number) {
        try {
            await api.delete(
                `showcaseproducts/remove-product/${product.showcase_product_id}`
            );

            products.splice(index, 1);

            setRefresh(!refresh);
        } catch (err) {
            error('Não foi possivel remover produto');
        }
    }

    function updateOrder(product: any, order: string) {
        if (+order <= 32767 && +order !== +product.order) {
            try {
                if (product.showcase_product_id) {
                    clearInterval(interval);

                    interval = setTimeout(async () => {
                        const body = { product_id: product.id, order };

                        await api.put(
                            `showcaseproducts/update-order/${product.showcase_product_id}`,
                            body
                        );

                        product.order = order;

                        setRefresh(!refresh);
                    }, 1000);
                } else {
                    product.order = order;

                    setRefresh(!refresh);
                }
            } catch (err) {
                error('Não foi possivel remover produto');
            }
        }
    }

    return (
        <TableContainer
            style={style}
            className={classes.tableContainer}
            component={Paper}
            elevation={0}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nome</TableCell>
                        <TableCell align="right">Marca</TableCell>
                        <TableCell align="right">Preço</TableCell>
                        <TableCell align="right">Categoria</TableCell>
                        <TableCell align="right">Sequência</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products.map((product, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                <PreviewImage image={product.image}>
                                    <img
                                        src={product.image}
                                        alt="Produto"
                                        onError={({ target }: any) =>
                                            (target.src = '/no-image.jpg')
                                        }
                                    />
                                </PreviewImage>
                                <span
                                    title={product.name}
                                    className={classes.ellipsis}
                                >
                                    {product.name}
                                </span>
                                {product.promotional_price && (
                                    <div
                                        className={classes.promotionalIndicator}
                                    />
                                )}
                            </TableCell>
                            <TableCell
                                align="right"
                                children={
                                    product.brand
                                        ? product.brand.name
                                        : `Sem Marca`
                                }
                            />
                            <TableCell align="right">
                                <span
                                    className={clsx({
                                        [classes.promotional]:
                                            product.promotional_price,
                                    })}
                                >
                                    {product.price}
                                </span>
                                {product.promotional_price && (
                                    <span style={{ marginLeft: 8 }}>
                                        {product.promotional_price}
                                    </span>
                                )}
                            </TableCell>
                            <TableCell
                                align="right"
                                children={product.categories}
                            />
                            <TableCell
                                align="right"
                                style={{ paddingRight: 0 }}
                            >
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    style={{ width: 30 }}
                                    defaultValue={product.order || ''}
                                    onChange={(event) =>
                                        updateOrder(product, event.target.value)
                                    }
                                />
                            </TableCell>
                            <TableCell align="right">
                                <IconButton
                                    onClick={() =>
                                        handleProduct(product, index)
                                    }
                                >
                                    {type === 'add' && (
                                        <Icon
                                            className={
                                                product.add
                                                    ? 'fas fa-check-circle'
                                                    : 'fa fa-plus-circle'
                                            }
                                            fontSize="small"
                                            style={{
                                                color: product.add
                                                    ? '#60BA57'
                                                    : '#456AEF',
                                            }}
                                        />
                                    )}
                                    {type === 'remove' && (
                                        <Icon
                                            className="fas fa-trash"
                                            fontSize="small"
                                            style={{ color: '#999999' }}
                                        />
                                    )}
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function ModalProducts(props: {
    id: number;
    open: boolean;
    onClose(data?: any[]): void;
}) {
    const classes = useStyles();
    const { open, onClose } = props;

    const [products, setProducts] = useState<any[]>([]);

    const auth = useContext(AuthContext);
    const { error } = useContext(AlertContext);

    let interval: any = null;

    function searchProducts(search: string) {
        if (search) {
            clearInterval(interval);

            interval = setTimeout(async () => {
                try {
                    const params = {
                        search,
                        // @ts-ignore
                        marketplace_id: auth.extra.marketplace.id,
                    };

                    const { data } = await api.get(
                        `showcaseproducts/search-products/${props.id}`,
                        { params }
                    );

                    for (const product of data) {
                        product.image = product.images[0]
                            ? product.images[0].midia_thumb_path ||
                              product.images[0].midia_path
                            : '';

                        product.categories = product.categories.reduce(
                            (acc: string, cur: any, index: number) => {
                                acc += cur.name;

                                if (index < product.categories.length - 1)
                                    acc += ', ';

                                return acc;
                            },
                            ''
                        );

                        product.price = currency(product.price);

                        if (product.promotional_price) {
                            product.promotional_price = currency(
                                product.promotional_price
                            );
                        }
                    }

                    setProducts([...data]);
                } catch (err) {
                    error('Não foi possivel encontrar produtos');
                }
            }, 1000);
        }
    }

    async function saveProduct() {
        try {
            const showcase_products: any[] = [];

            for (const product of products) {
                if (product.add) {
                    showcase_products.push({
                        product_id: product.id,
                        order: product.order,
                    });
                }
            }

            const { data } = await api.post(
                `showcaseproducts/add-product/${props.id}`,
                { showcase_products }
            );

            onClose(
                products.reduce((acc, cur) => {
                    if (cur.add) {
                        const showcase_product = data.find(
                            (data: any) => data.product_id === cur.id
                        );

                        cur.showcase_product_id = showcase_product.id;

                        cur.order = showcase_product.order;

                        acc.push(cur);
                    }

                    return acc;
                }, [])
            );

            setProducts([]);
        } catch (err) {
            error('Não foi possivel adicionar produtos');
        }
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                onClose();
                setProducts([]);
            }}
            className={classes.modal}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <Typography children="Adicionar produtos" />
                        <TextField
                            variant="outlined"
                            size="small"
                            style={{ width: '100%', backgroundColor: 'white' }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Icon
                                            className="fas fa-search"
                                            fontSize="small"
                                            style={{ color: '#999999' }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) =>
                                searchProducts(event.target.value)
                            }
                        />
                    </div>
                    <div className={classes.modalBody}>
                        <TableCustom
                            products={products}
                            type="add"
                            style={{ maxHeight: 300 }}
                        />
                    </div>
                    <div className={classes.modalFooter}>
                        <Button
                            children="Salvar"
                            className={classes.modalButton}
                            onClick={saveProduct}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}

export default function Products(props: ProductsProps) {
    const classes = useStyles();
    const { instance: values = {} } = props;
    const [modal, setModal] = useState(false);
    const editable = props.editable || props.editable === undefined;
    const [products, setProducts] = useState<any[]>([]);

    useEffect(() => {
        (async () => {
            if (values.id) {
                const { data } = await api.get(
                    `showcaseproducts/get-products/${values.id}`
                );

                const _product = [];

                for (const showcase_product of data) {
                    _product.push(showcase_product.product);

                    const product = _product[_product.length - 1];

                    product.order = showcase_product.order;

                    product.showcase_product_id = showcase_product.id;

                    product.image = product.images[0]
                        ? product.images[0].midia_thumb_path ||
                          product.images[0].midia_path
                        : '';

                    if (product.categories) {
                        product.categories = product.categories.reduce(
                            (acc: string, cur: any, index: number) => {
                                acc += cur.name;

                                if (index < product.categories.length - 1)
                                    acc += ', ';

                                return acc;
                            },
                            ''
                        );
                    } else {
                        product.categories = 'Sem categoria';
                    }

                    product.price = currency(product.price);

                    if (product.promotional_price) {
                        product.promotional_price = currency(
                            product.promotional_price
                        );
                    }
                }

                setProducts([..._product]);
            }
        })();
    }, []);

    return (
        <>
            {editable && (
                <div className={classes.content}>
                    <Typography className={classes.title}>
                        Produtos adicionados
                        <IconButton
                            onClick={() => setModal(true)}
                            className={classes.add}
                            size="small"
                        >
                            <Icon
                                className="fa fa-plus-circle"
                                style={{ color: '#456AEF' }}
                                fontSize="small"
                            />
                        </IconButton>
                    </Typography>
                    <TableCustom products={products} type="remove" />
                    <ModalProducts
                        id={values.id}
                        open={modal}
                        onClose={(data) => {
                            if (data) setProducts([...products, ...data]);

                            setModal(false);
                        }}
                    />
                </div>
            )}
        </>
    );
}
